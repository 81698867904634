import {onNuxtReady} from '#imports';
import {ref} from 'vue';

export function useHydrationState() {
    const isHydrated = ref(false);

    onNuxtReady(() => {
        isHydrated.value = true;
    });

    return {
        isHydrated,
    };
}
